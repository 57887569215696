import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';

import { ButtonIcon } from '@/components/shared/buttons/button-icon';
import { Text } from '@/components/shared/element/text';
import { ACCEPTED_COOKIES } from '@/const/cookie';
import logger from '@/lib/logger';
import { styled } from '@/stitches.config';

const PDPAWrapper = styled('div', {
  position: 'fixed',
  left: '$space-4',
  right: 48 + 16 + 16,
  bottom: '$space-6',
  zIndex: 1004,
  '@xs': {
    left: '$space-6',
    right: 48 + 24 + 24,
  },
  '@lg': {
    left: 56 + 32 + 32,
    right: 56 + 32 + 32,
  },
});

const PDPABox = styled('div', {
  position: 'relative',
  py: '$space-5',
  px: '$space-4',
  bc: '$white',
  bs: '$shadow-300',
  br: '$rounded-1',
  maxWidth: 1232,
  mx: 'auto',
  '@xs': {
    px: '$space-6',
  },
});

interface PdpaProps {
  title: string;
  description: string;
}

export const PDPA = ({ title, description }: PdpaProps) => {
  const [show, setShow] = useState<boolean>(false);
  useEffect(() => {
    try {
      const cookieAccepted = localStorage
        ? localStorage.getItem(ACCEPTED_COOKIES)
        : Cookies.get(ACCEPTED_COOKIES);
      if (!cookieAccepted) {
        setShow(true);
      }
    } catch (e) {
      logger.warn(e);
    }
  }, []);

  const agreeConsense = () => {
    try {
      localStorage
        ? localStorage.setItem(ACCEPTED_COOKIES, 'true')
        : Cookies.set(ACCEPTED_COOKIES, 'true');
      setShow(false);
    } catch (e) {
      logger.warn(e);
    }
  };

  return (
    show && (
      <PDPAWrapper>
        <PDPABox>
          <Text as="h2" font="bold" variant="text-red">
            {title}
          </Text>
          <Text
            as="div"
            wrap
            dangerouslySetInnerHTML={{ __html: description }}
            css={{ maxWidth: 1044, marginTop: '$space-2' }}
          />
          <ButtonIcon
            icon="close"
            variant="button-icon-color-red"
            size="md"
            isTransparent
            onClick={agreeConsense}
            css={{
              position: 'absolute',
              top: '$space-2',
              right: '$space-2',
            }}
          />
        </PDPABox>
      </PDPAWrapper>
    )
  );
};
PDPA.displayName = 'PDPA';
