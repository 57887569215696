const IconArrowDownward: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19 14L17.59 12.59L13 17.17V3H11V17.17L6.41 12.58L5 14L12 21L19 14Z" />
  </svg>
);
IconArrowDownward.displayName = 'IconArrowDownward';
export default IconArrowDownward;
