import { useEffect, useState } from 'react';

export const isServer = typeof window === 'undefined';

export const isFirefoxBrowser =
  !isServer && navigator.userAgent.toLowerCase().includes('firefox');

export const isIOSDevice = !isServer && /iPad|iPhone/.test(navigator.userAgent);

export function useLineBrowser() {
  const [isLine, setIsLine] = useState<boolean>(false);

  useEffect(() => {
    setIsLine(navigator.userAgent.includes('Line'));
  }, []);

  return isLine;
}

export const getIOSVersion = () => {
  if (!isIOSDevice) return null;
  const match = navigator.userAgent.match(/OS (\d+)_/);
  return match ? +match[1] : null;
};
