import logger from '@/lib/logger';
import type { LogService } from '@/lib/logger/log.type';

const formatLog = (input: Record<string, string>): string => {
  return Object.entries(input)
    .map(([key, value]) => `${key.toUpperCase()}|${value}`)
    .join('|');
};
const send = async ({
  url,
  status,
  headers,
  data,
  message,
  error,
}: LogService) => {
  const log = {
    URL: url ? `${url}` : '-',
    STATUS: status ? `${status}` : '-',
    HEADERS: headers ? JSON.stringify(headers) : '-',
    DATA: data ? JSON.stringify(data) : '-',
    ERROR: error ? JSON.stringify(error) : '-',
    MESSAGE: message ? `${message}` : '-',
  };

  logger.error(`LOG_ERROR_SERVICE|${formatLog(log)}`);
};

export const datadogLogs = { send };
