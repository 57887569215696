const IconArrowUpward: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5 10L6.41 11.41L11 6.83V21H13V6.83L17.59 11.42L19 10L12 3L5 10Z" />
  </svg>
);
IconArrowUpward.displayName = 'IconArrowUpward';
export default IconArrowUpward;
