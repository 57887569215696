interface LockScrollConfig {
  important?: boolean;
}

interface EnableLockScrollConfig extends LockScrollConfig {
  onlyOverflow?: boolean;
}

const LOCK_IMPORTANT_CLASSNAME = 'lock-scroll-important';
const LOCK_SCROLL_CLASSNAME = 'lock-scroll';
const LOCK_SCROLL_ONLY_OVERFLOW_CLASSNAME = 'lock-scroll-overflow';

export const clearBodyLockScroll = (config?: LockScrollConfig): void => {
  const BODY = document.body;
  if (BODY.classList.contains(LOCK_IMPORTANT_CLASSNAME) && !config?.important)
    return;

  if (
    BODY.classList.contains(LOCK_SCROLL_ONLY_OVERFLOW_CLASSNAME) ||
    BODY.classList.contains(LOCK_SCROLL_CLASSNAME)
  ) {
    BODY.classList.remove(
      LOCK_SCROLL_CLASSNAME,
      LOCK_SCROLL_ONLY_OVERFLOW_CLASSNAME,
      LOCK_IMPORTANT_CLASSNAME
    );
  }
};

export const enableBodyLockScroll = (config?: EnableLockScrollConfig): void => {
  const BODY = document.body;
  setTimeout(() => {
    BODY.classList.add(
      config && config.onlyOverflow
        ? LOCK_SCROLL_ONLY_OVERFLOW_CLASSNAME
        : LOCK_SCROLL_CLASSNAME
    );
    if (config && config.important) {
      BODY.classList.add(LOCK_IMPORTANT_CLASSNAME);
    }
  }, 50);
};
