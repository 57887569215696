/* eslint-disable no-console */

import { datadogLogs } from '@/lib/logger/datadog';
import type { LogService } from '@/lib/logger/log.type';

const formatLog = (logMessage: any) => {
  let message = logMessage;
  try {
    if (typeof message !== 'string') {
      message = JSON.stringify(logMessage);
    }

    return `${message}`;
  } catch (error) {
    return message;
  }
};

const logError = (message: any, ...args: any[]) => {
  return console.error(formatLog(message), ...args);
};

const logWarning = (message: any, ...args: any[]) => {
  return console.warn(formatLog(message), ...args);
};

const logInfo = (message: any, ...args: any[]) => {
  return console.log(formatLog(message), ...args);
};

const logDebug = (message: any, ...args: any[]) => {
  return console.log(message, ...args);
};

const logService = async (payload: LogService) => {
  return await datadogLogs.send(payload);
};

const logger = {
  error: logError,
  warn: logWarning,
  info: logInfo,
  debug: logDebug,
  service: logService,
};

export default logger;
