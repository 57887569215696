import { clearBodyLockScroll } from '@/components/shared/utility/lock-scroll';
import { keyframes, styled } from '@/stitches.config';

const animationEnd = keyframes({
  '0%': { transform: 'scaleX(0.2)', opacity: '1' },
  '30%': { transform: 'scaleX(1)', opacity: '1' },
  '100%': { transform: 'scaleX(1)', opacity: '0' },
});

const ProgressDiv = styled('div', {
  position: 'fixed',
  left: 0,
  top: 0,
  zIndex: '9999',
  width: '100%',
  height: 3,
  transformOrigin: 'left',
  transform: 'scaleX(0)',
  bc: '$primary',
  pe: 'none',
  bs: '$shadow100',
  '&.is-load': {
    transform: 'scaleX(0.2)',
    transition: 'transform .3s ease',
    '&.is-complete': {
      animationName: animationEnd,
      animationDuration: '0.5s',
      animationFillMode: 'both',
      animationTimingFunction: 'ease',
    },
  },
});

export const Progress = {
  start: () => {
    const $el = document.querySelector('.progress');
    if ($el) {
      $el.classList.add('is-load');
    }
  },
  complete: () => {
    const $el = document.querySelector('.progress');
    if ($el) {
      $el.classList.add('is-complete');
    }
  },
};

const View: React.FunctionComponent = () => {
  const handleAnimationEnd = (e) => {
    e.target.classList.remove('is-load', 'is-complete');
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.left = '';
    clearBodyLockScroll();
  };
  return (
    <ProgressDiv className="progress" onAnimationEnd={handleAnimationEnd} />
  );
};
View.displayName = 'SharedElementProgress';
export default View;
