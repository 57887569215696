const IconArrowForward: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m14 5-1.41 1.41L17.17 11H3v2h14.17l-4.59 4.59L14 19l7-7-7-7Z" />
  </svg>
);
IconArrowForward.displayName = 'IconArrowForward';
export default IconArrowForward;
