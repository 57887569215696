import { isServer } from '@/components/shared/utility/browser';

export const isLocalhost = () => {
  if (isServer) return false;

  return location.host.includes('localhost') || location.port != '';
};

export const isSynthetics = () => {
  if (isServer) return false;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return window._DATADOG_SYNTHETICS_BROWSER !== undefined;
};

export const isBot = () => {
  if (isServer) return false;

  const bots = [
    'bot',
    'crawl',
    'slurp',
    'spider',
    'mediapartners',
    'feedfetcher',
    'bingpreview',
    'facebookexternalhit',
    'linkedinbot',
    'twitterbot',
    'embedly',
    'quora link preview',
    'showyoubot',
    'outbrain',
    'pinterest',
    'slackbot',
    'vkShare',
    'w3c_validator',
    'nuzzel',
    'discordbot',
    'google page speed',
    'pingdom',
    'tumblr',
    'yandex',
    'GPTBot',
    'AdsBot-Google',
    'MJ12bot',
    'FacebookBot',
    'bingbot',
  ];

  const lowerUserAgent = navigator.userAgent.toLowerCase();

  return bots.some((bot) => lowerUserAgent.includes(bot));
};

export const isNetlifyPreview = () => {
  return location.host.includes('netlify.app');
};

export const isEmbedInIframe = (domain = 'gtm-msr.appspot.com') => {
  if (typeof window === 'undefined') return false;

  return document.referrer.includes(domain);
};

export const isCSI = () => {
  return location.pathname === '/csi';
};

export const isSSI = () => {
  return location.pathname === '/ssi';
};
