const IconArrowBack: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 19L11.41 17.59L6.83 13H21V11H6.83L11.42 6.41L10 5L3 12L10 19Z" />
  </svg>
);
IconArrowBack.displayName = 'IconArrowBack';
export default IconArrowBack;
