import { IWeb } from '@/types/config';

export const siteNameByWeb = (web: IWeb) => {
  switch (web) {
    case 'cv':
      return 'รถบรรทุกอีซูซุประเทศไทย ISUZU TRUCKS Thailand Official';
    case 'lcv':
      return 'อีซูซุประเทศไทย Isuzu Thailand Official';
  }
};

export const titleByWeb = (web: IWeb) => {
  switch (web) {
    case 'cv':
      return 'รถบรรทุก - ISUZU TRUCKS Thailand Official';
    case 'lcv':
      return 'อีซูซุประเทศไทย Isuzu Thailand Official';
  }
};

export const descriptionByWeb = (web: IWeb) => {
  switch (web) {
    case 'cv':
      return 'ISUZU TRUCKS Thailand Official เจ้าแห่งรถบรรทุก อีซูซุ คิงออฟทรัคส์';
    case 'lcv':
      return 'อีซูซุประเทศไทย ISUZU Thailand Official';
  }
};

export const ogImageByWeb = (web: IWeb) => {
  switch (web) {
    case 'cv':
      return 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd6be5b13bd044e9a/6601516eeee5d87634603537/og-isuzu-king-of-trucks-euro-5-max.jpg';
    case 'lcv':
      return 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb8ebe5359f8c9db3/62fb311dea0d864c464abcaf/OG_ISUZU.jpg';
  }
};
