const Calculator: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 22H18C19.104 22 20 21.104 20 20V4C20 2.896 19.104 2 18 2H6C4.896 2 4 2.896 4 4V20C4 21.104 4.896 22 6 22ZM9 19H7V17H9V19ZM9 15H7V13H9V15ZM9 11H7V9H9V11ZM13 19H11V17H13V19ZM13 15H11V13H13V15ZM13 11H11V9H13V11ZM17 19H15V13H17V19ZM17 11H15V9H17V11ZM6 4H18V7H6V4Z" />
  </svg>
);
Calculator.displayName = 'IconsCalculator';
export default Calculator;
